<div class="container">
  <div class="center_img">
    <img (click)="closePopup()" style="width:16px; height: 16px;cursor: pointer;" src="../../../assets/images/Vector.png" alt="NsureHub" />
  </div>
  <div class="center_img2">
    <img style="width:74.65px; height: 98px;" src="../../../assets/images/policy.png" alt="NsureHub" />
  </div>
  <p class="letter">Privacy Policy</p>
  <div class="letter1">
    <p class="letter_box">
      NsureHub is a insurance solution allowing you to purchase home insurance online. We at NsureHub appreciate the
      trust you place in us and are committed to protecting your nonpublic personal information and respecting your
      boundaries.
      Therefore, NsureHub provides this privacy policy (“Privacy Policy”) to describe the information we collect, how we
      use it and when and with whom we share it with. This Privacy Policy applies to information that we collect and use
      about you when you access or use our website located at NsureHub.com (the “Website”) or any product or service
      made available through the Website (together with the Website, the “Services”), including when you call or write
      to us.
      This Privacy Policy is made on February 2022.
      <br /><b>1. Acceptance of Privacy Policy</b><br />
      By accessing the Website or using the Services, you agree to this Privacy Policy. NsureHub may amend this Privacy
      Policy from time to time, at its sole discretion. It is our policy to post any changes we make to our Privacy
      Policy on this page. The date of the most recent revision to the Privacy Policy is identified above. You are
      responsible for periodically visiting our Website and this Privacy Policy to check for any changes.
      <br /><b>2. Information Collection</b><br />
      We obtain information about you through the means discussed below when we provide the Services. Please note that
      we need certain types of information to provide the Services to you. If you do not provide us with such
      information, or if you ask us to delete that information, you may no longer be able to access or use certain
      Services.
      Information you provide directly to us
      We may collect information that you provide directly to us:<br />
      • When you use our Services, e.g. when you create an account on the Website;<br />
      • When you fill out forms or respond to requests for information;<br />
      • When you request a quote for insurance or other products;<br />
      • When you register for promotions;<br />
      • When you transmit user content to us;<br />
      • When you request customer support and/or technical assistance;<br />
      • When you provide information to us via email, telephone or text message.<br />
      • When you otherwise communicate with us or other users through the Services;<br />
      The information you provide directly to us may concern you or others and may include, but is not limited to:<br />
      • Identifiers and Contact Information; We may collect identifiers and other contact information from you, such as
      your name, email address, phone number, date of birth, address, and driver’s license and any other information
      while creating an account.<br />
      • Property Information: We may collect information about your house or other property, e.g. the roof type, year
      built, or construction material.<br />
      • Financial Information: You may provide us or our service providers with financial information such as your
      income, credit score, insurance information, identity verification information, and/or financial account or credit
      card account information.<br />

      You are not required to provide us with such information, but certain features of the Services may not be
      accessible or available, absent the provision of requested information.
      Information from affiliates and non-affiliated third parties<br />

      We may obtain information about you from affiliates and non-affiliated third parties, which may include, but is
      not limited to:<br />
      • Consumer Reports: We may request information about your credit and claims history from a consumer reporting
      agency. By accessing the Services, you authorize us to request your consumer report.<br />
      • Property Information: We may obtain information about your house or other property from third parties, including
      third party vendors specializing in collecting data about property insurance risks.<br />
      • Information Provided for Payment Processing. When you make a payment through the Website, your payment will be
      processed by ACI XXXX or another payment processing vendor authorized by us. In processing such orders, ACI XXX or
      the applicable vendor will collect certain Personally Identifiable Information, including your name, telephone
      number, e-mail address, billing information (credit card number, CVV codes, and expiration date, bank routing
      numbers, and checking account numbers and billing address). NsureHub expressly disclaims any liability, financial
      or otherwise, associated with your interaction with any payment vendor.<br />
      We may combine information that we collect from you through the Services with information that we obtain from such
      third parties and information derived from other products or services we provide.
      <br /><b>Information we collect automatically</b><br />
      We may collect information that does not individually identify you, such as information about your internet
      connection, the equipment that you use to access our Services, and how you use our Services. We may aggregate such
      information into non-personal, statistical information about your use of the services, which we refer to as ‘Usage
      Information.’ This information allows us to optimize the Services to the internet connections and equipment that
      are used to visit the Website. For instance, in order to make the Services better, we may collect information on:
      how long you use the Services; whether you have used the Services before and on how many occasions; how frequently
      you use the Services; and at which point you exit the Services. We collect this information directly from you when
      you provide it to us and automatically as you navigate through and interact with our Services. Information
      collected this way includes: (a) details of your visits to our Services, including traffic data, location data,
      logs, other communication data, and the resources that you access and use on the Website; and (b) information
      about your computer and internet connection, including your IP address, operating system, and browser type. The
      technologies we use for automatic data collection of information
      <br /><b>from our Services may include:<br /></b>
      • Cookies (or browser cookies): A cookie is a small file placed on the hard drive of your computer. We use session
      cookies to keep track of your login information. You may refuse to accept browser cookies by activating the
      appropriate setting on your browser. However, if you select this setting you may be unable to access certain
      Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue
      cookies when you use the Services.<br />
      • Pixel Tags / Web Beacons:
      Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as
      clear gifs, pixel tags and single-pixel gifs) that permit us, for example, to count users who have visited those
      pages or opened an email. We may also use these web beacons for other related Website statistics (e.g., recording
      the popularity of certain content and verifying system and server integrity).<br />
      • Device identifiers: When you use a mobile device like a tablet or phone to access our Services, we may access,
      collect, monitor, store on your device, and/or remotely store one or more “device identifiers.” Device identifiers
      are small data files or similar data structures stored on or associated with your mobile device, which uniquely
      identify your mobile device. A device identifier may be data stored in connection with the device hardware, data
      stored in connection with the device’s operating system or other software, or data sent to the device by us.<br />
      <br /><b>3. Information Use</b><br />
      We use your information for business and commercial purposes, such as:<br />
      • To provide you with the Services, including to register you for an account and process transactions, or to
      process your application for insurance or other Services;<br />
      • To respond to your questions or inquiries, including to provide customer support and troubleshooting
      assistance;<br />
      • To communicate with you, including to send you emails or other communications about products and services that
      may interest you;<br />
      • To manage and remember your preferences and personalize the Services;<br />
      • To analyze and improve the Services or any other products and services we provide;<br />
      • To administer surveys or promotions;<br />
      • To verify your identity;<br />
      • To comply with our legal obligations or as permitted by law;<br />
      • To protect the safety and/or integrity of our users, employees, third parties, members of the public, and/or our
      Services;<br />
      • To prevent fraud and enforce our legal terms;<br />
      • To administer and troubleshoot the Services; and<br />
      • To show you online advertising for our products and services based on your interests on the Website or on other
      web sites. To display these advertisements, NsureHub or its third-party marketing partners may use technologies
      such as retargeting, cookies, cookie-less technologies, cross-device tracking, and digital audience
      targeting.<br />
      We may combine information that we collect from you through the Services with information that we obtain from
      affiliated and nonaffiliated third parties, and information derived from any other products or services we
      provide.<br />
      We may aggregate and/or de-identify information collected through the Services. We may use de-identified or
      aggregated data for any purpose, including without limitation for research and marketing purposes and may also
      share such data with any third parties.<br />
      <br /><b>4. Disclosure of Information</b><br />
      We may share or disclose information in the following ways:<br />
      • Your Consent: We may disclose your Personal Information to nonaffiliated third parties based on your consent to
      do so.<br />
      • Service Providers: We may disclose your Personal Information to contractors, partners, service providers, and
      other third parties we use to support our business, all of whom are bound by contractual obligations to keep
      Personal Information confidential and use it only for the purposes for which we disclose it to them.<br />
      • Business Transfers: In the event that NsureHub is involved in a merger, divestiture, restructuring,
      reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or
      as part of bankruptcy, liquidation, or similar proceeding, your Personal Information held by us may be among the
      assets transferred.<br />
      • Law and Harm: Notwithstanding anything to the contrary in this Privacy Policy, we may disclose or share your
      Personal Information as (we reasonably believe) is required by applicable law or regulation, including in order to
      comply with any court order, law, or legal process, including to respond to any government or regulatory request,
      or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of NsureHub,
      our customers, or others. This includes exchanging information with other companies and organizations for the
      purposes of fraud protection and credit risk reduction.<br />
      • Enforcement: We may disclose your Personal Information in order to enforce or apply our Terms and other
      agreements, including for billing and collection purposes.<br />
      • Disclosed when Collected: For any other purpose disclosed by us when you provide the information.<br />
      • Protection of Rights: If we believe disclosure is necessary or appropriate to protect the rights, property, or
      safety of NsureHub, our customers or others. This includes exchanging information with other companies and
      organizations for the purposes of fraud protection and credit risk reduction.<br />
      • We may disclose usage Information and aggregated information about our users without restriction. This
      information cannot be used to personally identify our users.<br />
      • We may share information collected from you or your devices with our partners to show targeted advertising on
      our Website or other websites, and to measure the performance of our ads.<br />
      <br /><b>5. Third-Party Service Providers and Links to Third Party Sites</b><br />
      We use third-party service providers (“Service Providers”) in order to efficiently provide our Services to you.
      Service Providers perform tasks including, but not limited to, verifying information that you provide. Some
      Service Providers will collect information directly from you and provide us with relevant data. We may use this
      information to carry out our obligations or enforce our rights. Information collected from Service Providers is
      governed by their privacy policies. You should view the privacy policies of Service Providers before providing
      information to them. If you interact with a third-party, the third-party may have access to your Personal
      Information, even though we do not disclose your Personal Information for these purposes without your
      consent.<br />
      The Website may include links to pages on third party websites, such as our social media pages etc, that we think
      will be of interest to you and are not operated by us. These third party websites have separate data collection
      and privacy practices independent from ours, and we are not responsible for the policies or activities of such
      other websites, including the handling of information you provide, once you leave the Website by linking to
      another site. Please contact such third parties directly if you have questions about their privacy policies.<br />
      <br /><b>6. Choices about How We Use and Disclose Your Information</b><br />
      We strive to provide you with choices regarding the information you provide to us.<br />
      Actions you may take on your own<br />
      • Tracking Technologies: You can set your browser to refuse all or some browser cookies, or to alert you when
      cookies are being sent. If you disable or refuse cookies, please note that many parts of the Website and Services
      may be inaccessible or will not function properly.<br />
      • Profile Information: You can update your profile information by accessing your Account.<br />
      • Account Safety<br />
      The safety and security of your information also depends on you.
      Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are
      responsible for keeping this password confidential.
      We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet
      is not completely secure.
      Although we do our best to protect your personal information, we cannot guarantee the security of your personal
      information transmitted to our Website.
      Any transmission of personal information is at your own risk.
      We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
      You must also seek to protect against unauthorized access to any information that you use in connection with the
      Website, and you should remember to close the browser when you step away from your computer and when you have
      completed your activities on the Website.
      If you choose to create an account on the Website (an “Account”), you must treat your user name, password, or any
      other piece of information related to your Account (“Account Information”) as confidential, and except as
      otherwise stated herein, you must not disclose Account Information to any other person or entity.
      You also agree to ensure that you exit from your Account at the end of each session.
      When accessing other parts of the Website, you should use particular caution when logging into and viewing your
      Account, and when submitting Personal information through the Website using public or shared computers so that
      others are not able to view or record your password or other information on your Account or otherwise. In the
      event that we determine that there has been a security breach resulting in the unauthorized disclosure of Personal
      information to a third party, we will notify individuals whose personal information has been so disclosed as
      required by law.
      Unfortunately, no data transmission over the Internet can be guaranteed to be absolutely secure.
      As a result, while we strive to protect personal information you submit to us, we cannot ensure or warrant the
      security of any information you transmit to us, and you do so at your own risk.
      NsureHub disclaims any liability, financial or otherwise, associated with the loss or misappropriation of your
      personal information due to authorized access that is beyond our.<br />

      Requests you may make to NsureHub to limit sharing<br />
      You may limit NsureHub sharing of each of the following by making a request via telephone, email, or mail to the
      Contact Information listed below:<br />
      • Credit Information: You may limit NsureHub sharing of Personal Information for NsureHub affiliates’ everyday
      business purposes by sending a request to NsureHub.<br />
      • Marketing: You may limit NsureHub sharing of Personal Information with affiliates and nonaffiliates to market to
      you by sending a request to NsureHub.<br />
      • Email Communications: If you do not wish to have your email address used by NsureHub to promote our own services
      or third parties, you may opt out of receiving promotional messages by sending a request to
      aortiz@safepointins.com or clicking on the opt out link in any email you receive. However, if you are a NsureHub
      customer you must provide an up-to-date and accurate email address for NsureHub to communicate with you regarding
      your insurance policy or any related claims.<br />
      Requests related to consumer reports<br />
      You are entitled to receive a free copy of your consumer report directly from the consumer reporting agency we
      utilize.<br />
      To receive your report, contact:<br />
      LexisNexis Consumer Center P.O. Box 105108 Atlanta, GA, 30348<br />
      To dispute your report, contact:<br />
      LexisNexis Consumer Center P.O. Box 105108 Atlanta, GA, 30348<br />

      <br /><b>7. Correcting Your Information</b><br />
      You may send us an email at NsureHub to request access to, correct, or delete any Personal Information that you
      have provided to us. We may not accommodate a request to change information if we believe the change would violate
      any law or legal requirement, the terms of your applicable insurance policy, or cause the information to be
      incorrect.
      <br /><b>8. Data Security</b><br />
      We take commercially reasonable measures to protect against the loss, misuse, and unauthorized access or
      disclosure, alteration and destruction of your Personal Information by aiming to maintain appropriate physical,
      procedural, and electronic safeguards to protect your personal data, including:<br />
      • Restricting access to personal data to our employees or Service Providers on a “need to know” basis;<br />
      • Enforcing policies and procedures for our employees in their handling of personal data; and<br />
      • Using technologies designed to safeguard data during its transmission, such as SSL encryption for the data you
      provide on our Website.<br />
      The safety and security of your information also depend on you. Where we have given you (or where you have chosen)
      a password for access to certain parts of our Website, you are responsible for keeping this password confidential.
      We ask you not to share your password with anyone.<br />
      Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best
      to protect your personal information, we cannot guarantee the security of your personal information transmitted to
      our Website. Any transmission of personal information is at your own risk. We are not responsible for
      circumvention of any privacy settings or security measures contained on the Website.<br />
      <br /><b>9. Children Under Age 18</b><br />
      Our Services are not intended for children under 18 years of age. No one under the age of 18 may provide any
      Personal Information on the Website or through the Services. We do not knowingly collect Personal Information from
      children under 18. If you are under 18, do not use or provide any information on the Service. If we learn we have
      collected or received Personal Information from a child under 18 without verification of parental consent, we will
      delete that information. If you believe we might have any information from or about any child under 18, please
      contact us at NsureHub.<br />
      <br /><b>10. Contact Information</b><br />
      To make any requests, ask questions or comment about this Privacy Policy and our privacy practices, contact us:
      1-888-678-7266<br />

      <br /><b>11. Governing Law and Jurisdiction</b><br />
      If any provision of this Privacy Policy is deemed invalid by a court of competent jurisdiction, the invalidity of
      such provision shall not affect the validity of the remaining provisions of this Privacy Policy, which shall
      remain in full force and effect, provided, however, that in such event this Privacy Policy shall be interpreted so
      as to give effect, to the greatest extent consistent with, and permitted by applicable law, to the meaning and
      intention of the excluded provision as determined by such court of competent jurisdiction.
      This Privacy Policy shall be governed by the laws of the United States, without respect to its conflict of laws
      principles. We each agree to submit to the personal and exclusive jurisdiction of the courts located in United
      States, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.

    </p>
  </div>
</div>