<div class="container">
        <div class="center_img">
                <img (click)="closePopup()" style="width:16px; height: 16px;cursor: pointer;"
                        src="../../../assets/images/Vector.png" alt="NsureHub" />
        </div>
        <div class="center_img2">
                <img style="width:69.2px; height: 98px;" src="../../../assets/images/page.png" alt="NsureHub" />
        </div>
        <p class="letter">Terms of Use</p>
        <div class="letter1">
                <p class="letter_box">
                        <b> Terms of service were last modified February 2022</b><br />
                        Thank your visiting [www.NsureHub.com] (“Website”) which is owned and operated by NsureHub,
                        together with its affiliates and subsidiary companies we are providing insurance services,
                        including issuance of insurance policies, to its customers.
                        These terms of service (“Terms”) govern your access to and use of NsureHub, or any product or
                        service made available through the Website (together with the Website, the “Services”).
                        We offer insurance Services such as Quoting and Binding Personal Lines Insurance and
                        (“Services”).
                        <br />
                        <b> Acceptance of the Terms and General Terms</b><br />
                        These Terms affect your legal rights and obligations. Please read these Terms thoroughly and
                        carefully. By using the Services, you agree to be bound by these Terms and by the applicable
                        Privacy Policy located found here Privacy Policy (“Privacy Policy”). If you do not agree to
                        these Terms or the Privacy Policy, then you may not access or use the Services.
                        All references to “you” or “your,” as applicable, mean the person who accesses, uses, and/or
                        participates in the Services in any manner, and each of your heirs, assigns, and successors. If
                        you use the Services on behalf of another individual, you represent and warrant that you have
                        the authority to bind that individual, your acceptance of the Terms will be deemed an acceptance
                        by that individual, and “you” and ”your” herein shall refer to you and that individual.
                        <br />
                        <b>you access the Services on behalf of a company, organization, or other entity, then</b><br />
                        i. “You” includes you and that entity, and
                        ii. You represent and warrant that you are authorized to grant all permissions and licenses
                        provided in these Terms and bind the entity to these Terms, and that you agree to these Terms on
                        the entity’s behalf.

                        These Terms may not describe all of the terms, conditions, exclusions, and exceptions applicable
                        to our Services. All insurance coverages are subject to the complete terms and conditions of the
                        insurance policies issued. Coverages, terms, and conditions and their availability may vary by
                        geographic location and individual risk circumstances. Additional terms and conditions may also
                        apply to specific portions, services, or features of the Website. All such additional terms and
                        conditions are hereby incorporated by this reference into these Terms.
                        This Website is offered and available to users who are 18 years of age and reside in [*] or any
                        of its territories. By using this Website, you represent and warrant that you are of the legal
                        age to form a binding contract with us. If you do not meet these requirements, you must not
                        access or use the Website. We do not authorize the use of the Website or collect any personally
                        identifiable information from any person whom we actually know is under the age of eighteen
                        (18).
                        By using the Services, you agree to the use of electronic signatures in connection with the
                        Services. All manifestations of assent, including but not limited to, click-throughs, voice
                        recordings, and typed or digitized handwritten signatures, shall have the same legal effect as
                        original handwritten signatures.
                        <br />
                        <b>Changes to the Terms</b><br />
                        We may revise these Terms from time to time in our sole discretion, and the most current version
                        will be posted on our Website. Modifications to the Terms will be effective upon the posting of
                        the modification to the Website and will apply to causes of action arising after thereafter.
                        You are responsible for reviewing the most current version of the Terms before using the Website
                        to ensure that you agree to any revisions to the Terms. If you do not agree to the new Terms,
                        you must stop using the Services.
                        Your continued use of the Services following any revision to these Terms will mean you accept
                        all of the modified terms and conditions. Moreover, you herby agree that these methods of
                        providing notice and acceptance of changes or amendments to these Terms and to the Website are
                        customary and reasonable.
                        <br />
                        <b>Account Information and Security</b><br />
                        In order to access the Services all customers are to create an account (“Account”) and obtain a
                        username and password to gain access. Unauthorized use of or access to these password restricted
                        areas is prohibited. Actual or attempted unauthorized use of or access to such areas may result
                        in criminal and/or civil prosecution. Attempts to access such areas without authorization may be
                        viewed, monitored and recorded, and any information obtained by us during such monitoring may be
                        given to law enforcement organizations in connection with any investigation or prosecution of
                        possible criminal activity on this system or the Website. If you are not an authorized User of
                        such areas or do not consent to continued monitoring, you should not attempt to access such
                        areas, or you should discontinue use of the Website. If you create an Account on the Website,
                        you must treat your user name, password, and any other information related to your Account
                        (“Account Information”) as confidential, and you must not disclose it to any other person or
                        entity. You acknowledge that your Account is personal to you and, except as provided herein, you
                        agree not to provide any other person with access to your Account, your Account Information, or
                        to any other security information. You will be solely responsible for all activity or use of the
                        Website associated with your Account, including any conduct which violates these Terms or our
                        Privacy Policy. We will not be responsible, financially or otherwise, if you do not properly
                        secure your password or if you choose to share your password with anyone else. Only such
                        accounts (i) that are authorized by law, and by each customer whose personally identifiable
                        information is contained on such Account, to receive access to all information provided on the
                        relevant Account; (ii) that have agreed, in writing, to refrain from disclosing Account
                        information or any information obtained through the Account to any unauthorized person and to
                        refrain from providing any unauthorized person with access to the Account; and (iii) whose
                        access is necessary in order to carry out our responsibilities shall be allowed access by third
                        parties. You agree to notify us immediately of any unauthorized access to your Account,
                        unauthorized use of your username or password, or any other breach of security. You also agree
                        to ensure that you exit from your Account at the end of each session. When accessing other parts
                        of the Website using public or shared computers, you should use particular caution when logging
                        into and using your Account, and when submitting personally identifiable information through the
                        Website, so that others are not able to view or record your password, Account Information, or
                        other information on your Account or otherwise. We have the right to disable any Account, user
                        name, password or other identifier, whether chosen by you or provided by us, at any time,
                        without notice, in our sole discretion for any reason or for no reason, including but not
                        limited to, if in our opinion, you have violated any provision of these Terms of Use. We also
                        reserve the right to log in or otherwise to access any Account on the Website, with the identity
                        of any user, including any insured party, producer, and vendor, for the purpose of preventing or
                        addressing service or technical problems, or otherwise in connection with customer support
                        matters, and may do so from time to time.
                        <br />
                        <b>Products or Services Offered </b><br />
                        The Services described on the Website are continuously being updated and changed, and it is
                        possible that some of the products and/or services displayed on the Website may not be available
                        at a particular time. Additionally, not all Services described on the Website may be available
                        in all states, and some products offered in a particular state may vary from the product
                        description included on the Website. We reserve the right to withdraw or change the Services at
                        any time and/or restrict or block access to the Website or Services, in our sole discretion
                        without notice. We are not responsible to you, or to any other party, if a product or service
                        displayed on the Website is not available for purchase at any particular time, or if a product
                        or service varies from a description provided on the Website. These Terms may not describe all
                        of the terms, conditions, exclusions, and exceptions applicable to the Services. All insurance
                        coverages are subject to the complete terms and conditions of the insurance policies issued.
                        Coverages, terms, and conditions and their availability may vary by geographic location and
                        individual risk circumstances. Additional terms of use may also apply to specific portions,
                        services, or features of the Website. All such additional terms of use are hereby incorporated
                        by this reference into these Terms of Use. The insurance products and services, including the
                        Policies, described on the Website are only intended for persons and entities in the states and
                        jurisdictions of the NsureHub where these insurance products and services may legally be sold.
                        Nothing on the Website shall be considered an offer to sell any insurance product or service to
                        any person or entity in any jurisdiction where such offer, purchase, or sale would be unlawful.
                        <br />
                        <b> Getting a Quote, Applying for a Policy and Coverages</b><br />
                        Quotes provided on the Website, may not be available for all states, products, services, or
                        coverage selections. To obtain a premium estimate and apply for an insurance policy on the
                        Website, you must enter certain personal information, which may include, but is not limited to,
                        the following personal information: contact information (e.g. full name, address, email address,
                        phone number), date of birth, and property-related information. In addition, NsureHub may obtain
                        supplemental personal information about a home or homeowner from third-party sources to produce
                        a more accurate premium estimate and to make business and/or underwriting decisions regarding
                        the potential insurance policy (together with the information in the preceding sentence, the
                        “Application Information”). NsureHub may also contact you and other parties to verify
                        Application Information through your Account provided to us or to obtain or provide additional
                        information. The submission of a completed Application to us does not automatically result in a
                        binding coverage agreement. Instead, an underwriter will review the application, determine
                        whether to approve the application, and will notify you, if the application has been approved.
                        If the application is approved. No insurance coverage can be bound, and no changes can be made
                        to a Policy, new or existing, unless and until a written binder has been received from us.
                        Coverages and availability may vary by state, and additional minimum coverage limits may be
                        available in your state. Online Claim Submissions. If you elect to report an insurance claim
                        through or using the Website, the information you submit regarding your insurance claim is
                        subject to review and verification by Us. We reserve the right to request additional information
                        regarding any claim in order to process or reach any coverage decision in accordance with its
                        policies and applicable law. A representative may communicate with you regarding your claim. All
                        policy provisions contained in your insurance policy remain in effect. If you have any questions
                        concerning the coverage afforded by your policy, please contact us by phone at 1-888-678-7266 or
                        email at info@nsurehub.com. Coverage for claims on your insurance policy will be determined in
                        accordance with the terms and conditions of your applicable Policy. Submitting a claim online
                        does not commit us to provide coverage for the alleged or reported loss. Information you submit
                        regarding your Policy and the loss is subject to review and verification

                        It is a condition of your use of the Services that all the information you provide to us is
                        correct, current, and complete. You represent and warrant that all information you provide to
                        us, including, without limitation, the Application Information, is true and accurate and does
                        not violate any federal, state, local, or international law or regulation. You agree that all
                        information you provide to us, including, but not limited to, through the use of any interactive
                        or messaging with us, is governed by our Privacy Policy, and you consent to all actions we take
                        with respect to your information consistent with our Privacy Policy. We will not be responsible
                        for any losses that result from your failure to provide correct and current information.

                        All premium estimates and quotes generated by us are based upon the information you provide and
                        are not a contract, binder, or agreement to extend insurance coverage. Any coverage descriptions
                        provided on our Website are general descriptions of available coverages and are not a statement
                        of contract. Prices and coverage limitations included in premium estimates and quotes change
                        based on your answers to our questions during your application, your choices for different
                        products, or due to changes of insurance pricing guides. Whenever you leave your application
                        prior to obtaining an insurance policy, your price may change. Your price and policy terms are
                        not final until you sign and pay.
                        <br />
                        <b>Submitting Payments</b><br />
                        When you make a payment through the Website, your payment will be processed by ACI XXXXX. The
                        terms and conditions of the payment processor can be found at ACI XXXX. We disclaim any
                        liability, financial or otherwise associated with your interaction with XXXXXXX, and you agree
                        to abide by XXXXXX’s terms of use.
                        <br />
                        <b>Consumer Reports and Credit-Based Information</b><br />
                        In connection with the Services, we may review a consumer report containing credit-based
                        information, including by obtaining or using a credit-based insurance score based on information
                        contained in your consumer report. An insurance score uses information from your credit report
                        and claims history to help predict how often you are likely to file claims and how expensive
                        those claims will be. Typical items from a consumer report that could affect a score include,
                        but are not limited to, the following: claims history, payment history, number of revolving
                        accounts, number of new accounts, the presence of collection accounts, bankruptcies, and
                        foreclosures.
                        We will not review your consumer report or obtain or use a credit-based insurance score when
                        doing so violates applicable law.
                        <br />
                        <b> Your Responsibility</b><br />
                        You represent that all of the information, data, and other materials that you provide on the
                        Website or to NsureHub through any other means, are true, accurate, and complete. You are
                        responsible for updating and correcting the information, including Personally Identifiable
                        Information, you have provided on the Website or to NsureHub through any other means, as
                        appropriate. You must not use the Website: (a) to violate any local, state, national, or
                        international law or regulation, including but not limited to the Gramm-Leach Bliley Financial
                        Modernization Act, as well as other applicable data security and privacy laws and regulations;
                        or (b) to interfere with or disrupt the servers or networks connected to the Website, or disobey
                        any requirements, procedures, policies, or regulations of networks connected to the Website.
                        Except as permitted pursuant to these Terms of Use or otherwise agreed by us in writing,
                        permission is hereby granted to the extent necessary to lawfully access and use the Website
                        and/or information, materials, products, and/or services available on it to display, download,
                        archive, and print in hard copy, portions of the Website on a temporary basis and for your
                        individual non-commercial use only, provided you do not modify the materials and that you retain
                        any and all copyright and other proprietary notices contained in the materials. NsureHub neither
                        warrants nor represents that use of content displayed on the Website will not infringe rights of
                        third parties not owned by or affiliated with NsureHub. Except as permitted pursuant to these
                        Terms of Use or otherwise agreed by us in writing, you must not display, reproduce, duplicate,
                        copy, sell, resell, or otherwise exploit for any commercial purposes, any portion of the
                        Website. Without our prior written consent, you may not: (a) allow, enable, or otherwise support
                        the transmission of mass unsolicited, commercial advertising or solicitations via e-mail (spam);
                        (b) use any high volume, automated, or electronic means (including without limitation robots,
                        spiders, scripts, or other automatic device) to monitor or copy the Website web pages or the
                        content contained thereon; (c) link or deep-link to the Website for any purpose except as
                        described in these Terms of Use; or (d) frame the Website, place pop-up windows over its pages,
                        or otherwise affect the display of the Website.
                        <br />
                        <b> Communicating with You</b><br />
                        You authorize NsureHub to contact you at the email address or telephone number that you have
                        provided to NsureHub for advertisement, telemarketing, solicitation or other purposes, including
                        via text message, automatic telephone dialer system, and/or pre-recorded voice to deliver a
                        message. You can revoke this consent at any time by contacting us by phone at 1-833-506-3452 by
                        email at aortiz@safepointins.com or by any other contact method listed on the Website. Consent
                        to receiving automated telephone calls or text messages from NsureHub is not a condition to
                        using the Services.
                        <br />
                        <b>Claims Submissions</b><br />
                        If you elect to report an insurance claim, the information you submit regarding your insurance
                        claim is subject to review and verification. NsureHub reserves the right to request additional
                        information regarding any claim. A representative may communicate with you regarding your claim.
                        All policy provisions contained in your policy remain in effect. Coverage for claims on your
                        insurance policy will be determined in accordance with the terms and conditions of the
                        applicable insurance policy.
                        <br />
                        <b>Prohibited Uses</b><br />
                        You may use the Services only for lawful purposes and in accordance with these Terms. In
                        connection with your use of the Services, you agree:
                        • Not to violate any applicable federal, state, local, or international law or regulation.
                        • Not to use the Services to send unsolicited or misleading messages.
                        • Not to (or attempt to) exploit, harm, or collect the personal information of minors in any way
                        by exposing them to inappropriate content, asking for personally identifiable information, or
                        otherwise.
                        • Not to impersonate or attempt to impersonate us, a NsureHub employee, another user or any
                        other person or entity (including, without limitation, by using email addresses or screen names
                        associated with any of the foregoing).
                        • Not to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
                        Services, or which, as determined by us, may harm us or users of the Services or expose them to
                        liability.
                        <br />
                        <b>Additionally,:</b><br />
                        • You agree not to use the Services in any manner that could disable, overburden, damage, or
                        impair the Website or interfere with any other party’s use of the Services, including their
                        ability to engage in real time activities through the Website.
                        • You agree not to use any robot, spider or other automatic device, process, or means to access
                        the Services for any purpose without our express written permission including monitoring or
                        copying any of the material on the Website.
                        • You agree not to conduct your business or use the Services in a manner that results in or may
                        result in complaints, disputes, claims, reversals, chargebacks, fees, fines, penalties, and
                        other liability to us, other users, third parties, or you.
                        • You agree not to use any manual process to monitor or copy any of the material on the Website
                        or for any other unauthorized purpose without our express written permission.
                        • You agree not to use any device, software, or routine that interferes with the proper working
                        of the Services.
                        • You agree not to introduce to the Website any viruses, trojan horses, worms, logic bombs, or
                        other material which is malicious or technologically harmful.
                        • You agree not to attempt to gain unauthorized access to, interfere with, damage, or disrupt
                        any aspect of the Services, the server(s) on which the Website is stored, or any server,
                        computer, or database connected to the Website.
                        • You agree not to attack the Website via a denial-of-service attack or a distributed
                        denial-of-service attack.
                        • You agree not to otherwise attempt to interfere with the proper working of the Website.
                        • You represent that all of the information, data, and other materials that you provide on the
                        Website or to us through any other means, are true, accurate, and complete.
                        • You are responsible for updating and correcting the information, including personally
                        identifiable information, you have provided on the Website or to us through any other means, as
                        appropriate.
                        • You shall not use the Website: (a) to violate any local, state, national, or international law
                        or regulation, including but not limited to the law of the United States, as well as other
                        applicable data security and privacy laws and regulations; or (b) to interfere with or disrupt
                        the servers or networks connected to the Website, or disobey any requirements, procedures,
                        policies, or regulations of networks connected to the Website. Except as permitted pursuant to
                        these Terms or otherwise agreed by us in writing, permission is hereby granted to the extent
                        necessary to lawfully access and use the Website and/or information, materials, products, and/or
                        services available on it to display, download, archive, and print in hard copy, portions of the
                        Website on a temporary basis and for your individual non-commercial use only, provided you do
                        not modify the materials and that you retain any and all copyright and other proprietary notices
                        contained in the materials.
                        • You agree not to without our prior written allow, enable, or otherwise support the
                        transmission of mass unsolicited, commercial advertising or solicitations via e-mail (spam);
                        • You agree not to link or deep-link to the Website for any purpose except as described in these
                        Terms
                        • You agree not to frame the Website, place pop-up windows over its pages, or otherwise affect
                        the display of the Website.
                        <br /><b> Third Parties’ Links, Websites, and Services</b><br />
                        The Services may contain links to third-party websites, advertisers, services, special offers,
                        or other events or activities that are not owned or controlled by us. We are not affiliated with
                        those websites, do not endorse them, have no control over those websites, and assume no
                        responsibility and/or liability for the content, privacy policies, or practices of any
                        third-party websites. In addition, we will not and cannot censor or edit the content of any
                        third-party site.
                        If you access any third party’s website, service, or content from our Services, you do so at
                        your own risk. By using the Services, you expressly release us (and our owners, employees,
                        agents, affiliates, and/or licensors) from any and all liability arising from your use of any
                        third-party website, information, materials, products, or services. Accordingly, we encourage
                        you to be aware when you have left the Services and to read the terms and conditions and privacy
                        policy of each other website that you visit.
                        <br /> <b>License to Services</b><br />
                        Subject to these Terms, we grant you a limited, non-transferable, non-exclusive, and revocable
                        permission to access and use our Services, provided that you:
                        i. Will not copy, distribute, or modify any part of the Services without our prior written
                        authorization;
                        ii. Will not send unsolicited or unauthorized advertisements, spam, chain letters, etc.;
                        iii. Will not transmit any content which contains software viruses or other harmful computer
                        code, files, or programs;
                        iv. Will not disrupt servers or networks connected to the Services; and
                        v. Will comply with these Terms. Using our Services does not give you ownership of any
                        intellectual property rights to our Services or the content you access.
                        <br />
                        <b>Open Source Software</b><br />
                        Certain software code incorporated into or distributed with the Services may be licensed by
                        third parties under various “open-source” or “public-source” software licenses (collectively,
                        the “Open Source Software”). Notwithstanding anything to the contrary in these Terms, the Open
                        Source Software is not licensed under these Terms and instead is separately licensed pursuant to
                        the terms and conditions of the applicable open-source software licenses. You agree to comply
                        with the terms and conditions of such open-source software license agreements.
                        <br />
                        <b> Intellectual Property Rights</b><br />
                        Unless otherwise indicated, the Website and its design, text, content, selection and arrangement
                        of elements, organization, graphics, compilation, magnetic translation, digital conversion, and
                        other matters related to the Website, its presentation, functionality, and “look and feel” are
                        protected under applicable copyright, trademark and other proprietary laws, including but not
                        limited to those of the United States, and all rights therein are the property of NsureHub, or
                        the material is included with the permission of the rights owner. You acknowledge that NsureHub
                        owns all right, title, and interest in and to all of the information on the Website, and all
                        underlying software and technology, including without limitation all Intellectual Property
                        Rights (or that the material is included on the Website with the permission of the rights
                        owner). “Intellectual Property Rights” means any and all rights existing from time to time under
                        patent law, copyright law, trade secret law, trademark law, unfair competition law, any and all
                        other proprietary rights, and any and all applications, renewals, extensions, and restorations
                        thereof, now or hereafter in force and effect worldwide. Except as expressly set forth herein,
                        you are not authorized to use any portion of this Website, our Content (defined below), or any
                        of our Intellectual Property Rights on any other website, in the meta-tags of any other website,
                        or in any other materials. NsureHub and its licensors reserve all other rights not expressly
                        granted in these Terms of Use.
                        <br /> <b>TRADEMARKS</b><br />. Certain of the names, logos, and other materials displayed on
                        the Website constitute trademarks, tradenames, service marks, or logos (“Marks”) of NsureHub or
                        other entities. You are not authorized to use any such Marks. Ownership of all such Marks and
                        the goodwill associated therewith remains with NsureHub or those other entities.
                        COPYRIGHTS; RESTRICTIONS ON USE. The content made available to you on or through the Website,
                        including without limitation, text, databases, software, code, music, sound, photos, and
                        graphics (“Content”), is: (a) copyrighted by NsureHub, its affiliates, and/or its licensors
                        under the applicable law and international copyright laws; (b) subject to other intellectual
                        property and proprietary rights and laws; and (c) owned by NsureHub, its affiliates, or its
                        licensors. Except as permitted in the Terms of Use or otherwise agreed by us in writing, our
                        Content may not be copied, modified, reproduced, republished, posted, transmitted, sold, offered
                        for sale, performed, displayed, or redistributed in any way without our prior written permission
                        and/or the prior written permission of our applicable licensors. You must abide by all copyright
                        notices, information, or restrictions contained in or attached to any of our Content.
                        <br /> <b> Disclosure</b><br />
                        We reserve the right to access, read, preserve, and disclose any information as we reasonably
                        believe is necessary to:
                        i. Satisfy any applicable law, regulation, legal process, subpoena, or governmental request;
                        ii. Enforce these Terms, including investigation of potential violations of it;
                        iii. Detect, prevent, or otherwise address fraud, security, or technical issues;
                        iv. Cooperate with law enforcement authorities;
                        v. Respond to user support requests;
                        vi. Protect our, our users’, or the public’s rights, property, or safety; or
                        vii. Improve the user’s experience on the Website.
                        <br /><b>Disclaimer of Warranties</b><br />
                        You understand that we cannot and do not guarantee or warrant that files available for
                        downloading from the Website will be free of viruses or other destructive code. You are
                        responsible for implementing sufficient procedures and checkpoints to satisfy your particular
                        requirements for anti-virus protection and accuracy of data input and output, and for
                        maintaining a means external to our site for any reconstruction of any lost data.ment, computer
                        programs, data, or other proprietary material due to your use of the services, or items obtained
                        through the website, or your downloading of any material posted on the website, or on any
                        website linked to the website.
                        your use of the services, including the website, its content, or items obtained through the
                        website, is at your own risk. the website, its content, and the platform or items obtained
                        through the website are provided on an “as is” and “as available” basis, without any warranties
                        of any kind, either express or implied. neither nsurehub nor any person associated with nsurehub
                        makes any warranty or representation with respect to the completeness, security, reliability,
                        quality, accuracy, or availability of the services. without limiting the foregoing, neither
                        nsurehub nor anyone associated with nsurehub represents or warrants that the website, its
                        content, or any services or items obtained through the website will be accurate, reliable,
                        error-free, or uninterrupted, that defects will be corrected, that our website or the server
                        that makes it available are free of viruses or other harmful components, or that the services
                        will otherwise meet your needs or expectations.
                        nsurehub hereby disclaims all warranties of any kind, whether express or implied, statutory or
                        otherwise, including, but not limited to, any warranties of merchantability, non-infringement,
                        and fitness for a particular purpose.
                        the foregoing does not affect any warranties which cannot be excluded or limited under
                        applicable law.
                        <br />
                        <b>Limitation on Liability</b><br />
                        we will not be liable for any loss or damage caused by a distributed denial-of-service attack,
                        viruses, or other technologically harmful material that may infect your computer equip in no
                        event will the collective liability of nsurehub, or its licensors, service providers, employees,
                        agents, managers, officers, or directors to any party (regardless of the form of action, whether
                        in contract, tort, or otherwise) exceed $100. in no event will nsurehub or its licensors,
                        service providers, employees, agents, managers, officers, or directors be liable for indirect,
                        special, incidental, consequential, or punitive damages, including, but not limited to, pain and
                        suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated
                        savings, loss of use, loss of goodwill, loss of data, and whether caused by tort (including
                        negligence), breach of contract, or otherwise, even if foreseeable.
                        the foregoing does not affect any liability which cannot be excluded or limited under applicable
                        law
                        <br />
                        <b> Release</b><br />
                        in exchange for the services provided by us and other good and valuable consideration, the
                        receipt and sufficiency of which is acknowledged, you expressly agree to release nsurehub, its
                        officers, directors, affiliates, parent companies, partners, employees, and agents from any
                        claims, demands, causes of action, losses, and damages (direct and consequential) of every kind
                        and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out
                        of or in any way connected with any dispute you have, except that this release does not apply to
                        claims by you directly arising out of nsurehub material breach of these terms or written
                        agreement.
                        <br />
                        <b> Limitation on Time to File Claim</b><br />
                        any cause of action or claim you may have arising out of or relating to these terms of use or
                        the website must be commenced within one (1) year after the cause of action accrues, otherwise
                        such cause of action or claim is permanently barred.

                        the foregoing does not affect any limitation period available under applicable law
                        <br />
                        <b>Indemnification</b><br />
                        You agree to defend, indemnify, and hold harmless us, our affiliates, and our and their
                        respective owners, officers, directors, employees, agents, licensors, and/or successors and
                        assigns from and against any and all claims, damages, obligations, losses, liabilities, costs
                        and expenses (including but not limited to attorneys’ fees) arising from:
                        i. Your use of the Services;
                        ii. Your violation of these Terms;
                        iii. Any inaccuracies in your Application Information;
                        iv. Your violation of any third-party right, including without limitation any copyright,
                        property, publicity, or privacy right; including all actions taken under your account.
                        This defense, hold harmless, and indemnification obligation will survive any termination of
                        these Terms and your use of the Services.
                        Governing Law and Jurisdiction
                        All matters relating to the Website and these Terms and any dispute or claim arising therefrom
                        or related thereto (in each case, including non-contractual disputes or claims), shall be
                        governed by, and construed in accordance with, the internal laws of the United States without
                        giving effect to any choice or conflict of law provision or rule.
                        Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Website
                        shall be instituted exclusively in the federal courts of the United States. You waive any and
                        all objections to the exercise of jurisdiction over you by such courts and to venue in such
                        courts.
                        Dispute Resolution
                        To the fullest extent permitted by applicable law, any dispute relating in any way to your visit
                        to our Website and any purchase by you of our Services (unless expressed otherwise in the
                        Insurance Policy) shall be submitted to confidential arbitration in Florida and you consent to
                        the exclusive jurisdiction and venue in such courts. Arbitration under this agreement shall be
                        conducted under the rules then prevailing under the applicable laws.
                        <br />
                        <b>Termination</b><br />
                        You agree that we, in our sole discretion and without providing notice to you, may terminate
                        your use of, and/or access to the Website if we believe that you have violated or acted
                        inconsistently with the letter or spirit of these Terms of Use. FURTHER, YOU AGREE THAT WE SHALL
                        NOT BE LIABLE (FINANCIALLY OR OTHERWISE) TO YOU OR ANY OTHER PARTY FOR ANY TERMINATION OF YOUR
                        ACCESS TO THE WEBSITE.
                        <br />
                        <b>Waiver and Severability</b><br />
                        No waiver by [*] of any term or condition set forth in these Terms shall be deemed a further or
                        continuing waiver of such term or condition or a waiver of any other term or condition, and any
                        failure of [*] to assert a right or provision under these Terms shall not constitute a waiver of
                        such right or provision.
                        If any provision of these Terms is held by a court or other tribunal of competent jurisdiction
                        to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or
                        limited to the minimum extent such that the remaining provisions of the Terms will continue in
                        full force and effect.
                        <br /> <b> Entire Agreement</b><br />
                        These Terms and our Privacy Policy constitute the sole and entire agreement between you and [*]
                        with respect to the Website and supersede all prior and contemporaneous understandings,
                        agreements, representations, and warranties, both written and oral, with respect to the Website,
                        except to the extent such terms are contained in a written contract.
                        [*] reserves the right, in its sole and absolute discretion, to transfer, assign, sublicense, or
                        pledge in any manner whatsoever, any of its rights and obligations under these Terms to a
                        subsidiary, affiliate, successor, or to any third party whatsoever, without notifying you or
                        receiving your consent. You shall not transfer, assign, delegate, sublicense, nor pledge, in any
                        manner whatsoever, any of your rights or obligations under these Terms.
                        <br /> <b>Your Comments and Concerns</b><br />
                        All feedback, comments, requests for technical support, and other communications relating to the
                        Website should be directed to NsureHub.
                        When you submit any ideas, suggestions, documents and/or proposals relating to the Service (or
                        other products or services) to NsureHub through the “Contact Us,” User Forum, or Support
                        interfaces, or through any other channel or mechanism (collectively, “Contributions”), you
                        acknowledge and agree that:
                        i. Your Contributions do not contain confidential or proprietary information;
                        ii. [*] is not under any obligation of confidentiality, express or implied, with respect to the
                        Contributions;
                        iii. [*] shall be entitled to use or disclose (or choose not to use or disclose) such
                        Contributions for any purpose, in any way;
                        iv. [*] may have something similar to the Contributions already under consideration or in
                        development;
                        v. Your Contributions automatically become the property of [*] without any obligation of [*] to
                        you; and
                        You are not entitled to any accounting, compensation, or reimbursement of any kind from [*]
                        under any circumstances.
                        <br /><b>General</b><br />
                        You acknowledge and agree that these Terms together with our Privacy Policy, constitute the
                        complete and exclusive agreement between us concerning your use of the Website and any purchase
                        by you of any Services from us and supersedes and govern all prior proposals, agreements or
                        other communications, save for the insurance policy.
                        No failure to exercise and no delay on the part of either party in exercising any right, remedy,
                        power or privilege of that party under these Terms and no course of dealing between the parties
                        shall be construed or operate as a waiver thereof, nor shall any single or partial exercise of
                        any right, remedy, power or privilege preclude any other or further exercise thereof or the
                        exercise of any right, remedy, power or privilege. The rights and remedies provided by these
                        Terms are cumulative and are not exclusive of any rights or remedies provided by law.
                        Nothing contained in these Terms shall be construed as creating any agency, partnership or other
                        form of joint enterprise between us.
                        We reserve the right to refuse to supply Services to any person for any reason whatsoever, to
                        withdraw any Services from our Website at any time and/or remove or edit any materials or
                        contents of the Website. We will not be liable to you or any third party by reason of our
                        withdrawing of any Service from this Website whether or not such Services have been sold;
                        removing or editing content on the Website; refusing to process a transaction or unwinding or
                        suspending any service after it has begun.


                </p>
        </div>

</div>