<header>
  <div class="left-header-area">
    <div class="logoContainer">
      <img (click)="goToHomePage1()" src="../../../../assets/images/nsurehub/logo.png" alt="NsureHub" />
    </div>
  </div>
  <div class="right-header-area" *ngIf="!isQuoteHeader">
    <div class="mobile-menu">
      <div class="mobileMenuTop">
        <mat-icon *ngIf="!showMobileMenu" (click)="showMobileMenu = !showMobileMenu">menu</mat-icon>
        <mat-icon *ngIf="showMobileMenu" (click)="showMobileMenu = !showMobileMenu">close</mat-icon>
      </div>
      <div class="mobileMenuBottom">
        <span id="cta-call-header-mobile">
          <a href="tel:+18886787266" class="phone-number">
            <mat-icon mat-dialog-close class="close">call</mat-icon>
            <span>1-888-678-7266</span>
          </a>
          <a href="mailto:info@nsurehub.com">
            <span class="phone-number">
              <mat-icon mat-dialog-close class="close">mail_outline</mat-icon>
              <span>info@nsurehub.com</span>
            </span>
          </a>
      <!-- Remove Auto Quote  </span>
        <button class="button-fill" routerLink="/auto-turborater">INSTANT AUTO QUOTE</button> -->
    <!-- Client Portal Button -->
    <a href="https://my.gloveboxapp.com?agencyId=4147">
      <button class="button-fill">CLIENT PORTAL</button>
    </a>
        <!-- <a id="cta-quote-header" routerLink="/">
          Get a Quote
        </a> -->
        </span>
      </div>
      <div class="mobile-Nav" *ngIf="showMobileMenu">
        <ul>
          <li routerLink="/">Home</li>
          <li routerLink="/auto-insurance">Auto Insurance</li>
          <li routerLink="/home-discounts">Insurance Discounts</li>
          <li routerLink="/life-insurance">Life Insurance</li>
          <li routerLink="/credit-repair">Credit Repair </li>
          <!-- <li onclick="window.location.href='https://nsurehub.hotelplanner.com/';" title="Travel Discounts">Travel
            Discounts</li> -->
          <!-- <li routerLink="/pet-insurance">Pet Insurance</li> -->
          <li routerLink="/flood-insurance">Flood Insurance</li>
          <li routerLink="/blog-insurance">Insurance Blog</li>
          <li routerLink="/florida-homeowners">Florida Homeowners</li>
          <li routerLink="/self-inspection">Self Inspection</li>
          <li routerLink="/loss-prevention">Loss Prevention</li>
          <li routerLink="/texas-homeowners">Texas Homeowners</li>
          <li routerLink="/sickpay-plus">Sick Pay Plus</li>
          <li routerLink="/accident-death" title="Accident">Accident Death and Dismemberment</li>
          <li routerLink="/highvalue-homes">High Value Homes</li>
          <li routerLink="/theft-id">ID Theft</li>
          <li routerLink="/home-appliance">Home Appliance & Equipment Coverage</li>
          <li routerLink="/policy-change">Policy Change</li>
          <li routerLink="/aboutus">About Us</li>
          <li routerLink="/faq">FAQ</li>
          <li routerLink="/contacts">Contact</li>
        </ul>
      </div>
    </div>
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-end align-items-center">
            <!-- <a class="d-none d-md-inline-block" id="cta-quote-header" routerLink="/">
              Get a Quote
            </a> 
            <button class="button-fill" routerLink="/instant-home-policy">INSTANT HOME POLICY</button>
            <span class="mx-md-4 d-inline-block"></span>
            <button class="button-fill" routerLink="/auto-turborater">INSTANT AUTO QUOTE</button>
             <a href="#" class=""><span class="d-none d-md-inline-block">1+ (234)
                5678 9101</span></a> -->
           
                <a href="tel:+18886787266" class="phone-number">
                  <mat-icon mat-dialog-close class="close">call</mat-icon>
                  <span>1-888-678-7266</span>
                </a>
                <a href="mailto:info@nsurehub.com">
                  <span class="phone-number">
                    <mat-icon mat-dialog-close class="close">mail_outline</mat-icon>
                    <span>info@nsurehub.com</span>
                  </span>
                </a>
        <!-- Client Portal Button -->
        <a href="https://my.gloveboxapp.com?agencyId=4147">
          <button class="button-fill">CLIENT PORTAL</button>
        </a>
          </div>
        </div>
      </div>
    </div>
    <nav id="main-menu">
      <ul>
        <li routerLink="/">Home</li>
        <li>About Us
          <ul>
            <li routerLink="/aboutus">About Us</li>
            <li routerLink="/florida-homeowners">Florida Homeowners</li>
            <li routerLink="/loss-prevention">Loss Prevention</li>
            <li routerLink="/texas-homeowners">Texas Homeowners</li>
          </ul>
        </li>
        <li *ngIf="false">Services
          <ul>
            <li routerLink="/credit-repair">Credit/Repair/Lower Premiums</li>
            <li onclick="window.location.href='https://nsurehub.hotelplanner.com/';">Hotel Discounts</li>
            <li routerLink="/theft-id">ID Theft</li>
            <li routerLink="/self-inspection">Self Inspection</li>
            <li routerLink="/home-discounts">Smart Home/Water & Security Systems</li>
            <li onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
              title="Telemedicine">Telemedicine
            </li>
          </ul>
        </li>
        <li *ngIf="false">Insurance Products
          <ul style="margin-top: 30px;">
            <li routerLink="/auto-insurance">Auto Insurance</li>
            <li routerLink="/accident-death" title="Accident">Accident Death and Dismemberment</li>
            <li routerLink="/flood-insurance">Flood Insurance</li>
            <li onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';"
              title="Health Insurance">Health Insurance</li>
            <li routerLink="/highvalue-homes">High Value Homes</li>
            <li routerLink="/home-appliance">Home Appliance & Equipment Coverage</li>
            <li routerLink="/life-insurance">Life Insurance</li>
            <li routerLink="/pet-insurance">Pet Insurance</li>
            <li routerLink="/sickpay-plus">Sick Pay Plus</li>
          </ul>
        </li>
        <li routerLink="/policy-change">Policy Change</li>
        <li routerLink="/faq">FAQ</li>
        <li routerLink="/contacts">Contact
        </li>
        <li routerLink="/blog-insurance">Blog</li>
      </ul>
    </nav>
  </div>
  <div class="header-icon">
    <ul>
      <li routerLink="/">
        <img src="../../assets/images/header-icon/home.png" alt="NsureHub" />
        <p>Home</p>
      </li>
      <li (click)="openLink()" title="Life">
        <img src="../../assets/images/header-icon/life.png" alt="NsureHub" />
        <p>Life</p>
      </li>
      <!-- <li onclick="window.location.href='https://figopetinsurance.com/?p=9e9T0V7O7s7#get-a-quote/';" title="Pets">
        <img src="../../assets/images/header-icon/pets.png" alt="NsureHub" />
        <p>Pet</p>
      </li> -->
      <li routerLink="/auto-turborater" title="Auto">
        <img src="../../assets/images/header-icon/auto.png" alt="NsureHub" />
        <p>Auto</p>
      </li>
      <li onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';" title="Umbrella"
        *ngIf="false">
        <img src="../../assets/images/header-icon/umbrella.png" alt="NsureHub" />
        <p>Umbrella</p>
      </li>
      <li onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';" title="Flood"
        *ngIf="false">
        <img src="../../assets/images/header-icon/flood.png" alt="NsureHub" />
        <p>Flood</p>
      </li>
      <li onclick="window.location.href='http://ec2-107-23-176-20.compute-1.amazonaws.com/NsureHub';" title="Health"
        *ngIf="false">
        <img src="../../assets/images/header-icon/health.png" alt="NsureHub" />
        <p>Health</p>
      </li>
      <!-- <li onclick="window.location.href='https://nsurehub.hotelplanner.com/';" title="Travel">
        <img src="../../assets/images/header-icon/travel.png" alt="NsureHub" />
        <p>Travel</p>
      </li> -->
      <li
        onclick="window.location.href='https://tracking.thecreditpros.com/aff_c?offer_id=91&aff_id=3435&promo_number=866-648-1097&first_name=&last_name=&email=&phone_number=&GUID=&adv_sub=/';"
        title="Credit Repair" *ngIf="false">
        <img src="../../assets/images/header-icon/credit.png" alt="NsureHub" />
        <p>Credit Repair</p>
      </li>
    </ul>
  </div>

  <div class="right-header-area quoteRight " *ngIf="isQuoteHeader" style="margin-top: -6%; z-index: -999;">
    <div class="clientAddress">
      <h3>{{propAddress}}</h3>
      <span id="cta-call-header-quote">
        <mat-icon mat-dialog-close class="close">call</mat-icon><a href="tel:+18886787266">1-888-678-7266</a>
      </span>
    </div>
    <div class="clientSteps">
      <h2>Step {{this.appService.currentStep=='5'?4:this.appService.currentStep}} of 4</h2>
      <div class="steps-container" *ngIf="!showMenu">
        <span class="stepsHeadLabel">Step:</span>
        <ul class="steps-list">
          <li class="step" [class.stepCompleted]="this.appService.currentStep>1" (click)="stepClicked(1)">
            <span>1</span>
          </li>
          <li class="step" [class.stepCompleted]="this.appService.currentStep>2"
            [class.stepInProgress]="this.appService.currentStep=='2'" (click)="stepClicked(2)"><span>2</span></li>
          <li class="step" [class.stepCompleted]="this.appService.currentStep>3"
            [class.stepInProgress]="this.appService.currentStep=='3'" (click)="stepClicked(3)"><span>3</span></li>
          <li class="step" [class.stepCompleted]="this.appService.currentStep>4"
            [class.stepInProgress]="this.appService.currentStep=='4'" (click)="stepClicked(4)"><span>4</span></li>
        </ul>

        <div class="stepsLine"></div>
      </div>
      <!-- <div class="menuinQuote" *ngIf="showMenu">
          <ul>
            <li routerLink="/">Home</li>
            <li routerLink="/auto-insurance">Auto Insurance</li>
            <li routerLink="/home-discounts">Insurance Discounts</li>
            <li routerLink="/life-insurance">Life Insurance</li>
            <li routerLink="/credit-repair">Credit Repair </li>
            <li onclick="window.location.href='https://nsurehub.hotelplanner.com/';">Travel Discounts</li>
            <li routerLink="/pet-insurance">Pet Insurance</li>
            <li routerLink="/flood-insurance">Flood Insurance</li>
            <li routerLink="/blog-insurance">Insurance Blog</li>
            <li routerLink="/florida-homeowners">Florida Homeowners</li>
            <li routerLink="/texas-homeowners">Texas Homeowners</li>
            <li routerLink="/aboutus">About Us</li>
            <li routerLink="/faq">FAQ</li>
            <li routerLink="/contacts">Contact</li>
          </ul>
        </div> -->
      <!-- <span class="stepsHeadLabel menuimg" (click)="showMenu = !showMenu">
          <mat-icon *ngIf="!showMenu">menu</mat-icon>
          <mat-icon *ngIf="showMenu">close</mat-icon>
        </span> -->
      <p>Call us to modify coverage or to receive a quote from another carrier.</p>
    </div>
  </div>

</header>